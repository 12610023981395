/* global tw */
import React from "react";
import styled from "styled-components";

const CbBlock = styled.div`
	${tw`p-6 mx-auto`};
	max-width: 1500px;
`;

const Cardboard = props => {
	return <CbBlock className="cardboard">{props.children}</CbBlock>;
};

export default Cardboard;
