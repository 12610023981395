/* global tw */
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
// import get from "lodash/get";
// import PropTypes from "prop-types";

const HeaderBlock = styled.header`
	${tw`p-6 justify-between relative z-10 hidden sm:flex sm:flex-col h-screen`};
`;

// w-screen

const MegaTitle = styled.h1`
	${tw`w-3/4 font-dincond uppercase font-black text-8xl leading-mini`};
`;

const TextBody = styled.div`
	${tw`font-dincond font-black uppercase text-3xl my-0`};

	p {
		${tw`text-black`};
	}

	a {
		${tw`text-cb-red`};
	}
`;

const BgBlock = styled.div`
	${tw`fixed pin-y pin-x`};
	z-index: -1;
	animation-name: zoomScale;
	animation-duration: 16s;
	animation-timing-function: ease;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
	&:before {
		content: "";
		background: rgba(0, 0, 0, 0.3);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
	}
`;

const BgImg = styled.img`
	${tw`object-fit-cover w-full h-full`};
`;

const HomeFeatureBlock = ({ data }) => {
	const homefeature = data.recraft.entries[0].homepageFeatureBlock[0];

	return (
		<HeaderBlock className="w-lessnav">
			<MegaTitle className={homefeature.invert ? "text-white" : "text-black"}>
				{data.recraft.entries[0].title}
			</MegaTitle>
			<div className="shadowed cardboard p-4">
				<div className="block">
					{homefeature.colloquially ? (
						<TextBody
							dangerouslySetInnerHTML={{
								__html: homefeature.colloquially.content
							}}
						/>
					) : null}
				</div>
			</div>
			<BgBlock>
				<BgImg
					src={
						homefeature.backgroundImage[0].url +
						"?fm=jpg&auto=compress,enhance,format&fit=crop&max-w=2000&max-h=1500&blend=262626&bm=multiply&balph=37"
					}
				/>
			</BgBlock>
		</HeaderBlock>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query HomeFeatureQuery {
				recraft {
					entries(section: homepageBuilder) {
						title
						... on Recraft_HomepageBuilder {
							id
							homepageFeatureBlock {
								id
								backgroundImage {
									url
									focalPoint {
										x
										y
									}
								}
								overlayOpacity
								invert
								colloquially {
									content
								}
							}
						}
					}
				}
			}
		`}
		render={data => <HomeFeatureBlock data={data} {...props} />}
	/>
);

// HomeFeatureBlock.propTypes = {
// 	data: PropTypes.shape({
// 		recraft: PropTypes.shape({
// 			entries: PropTypes.shape({
// 				title: PropTypes.string.isRequired
// 			}).isRequired
// 		}).isRequired
// 	}).isRequired
// };
