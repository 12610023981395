import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
// import PageContentBuilder from "../components/PageContentBuilder";
// import Card from "../components/Card";
import Cardboard from "../components/Cardboard";
import CardSelections from "../components/CardSelections";
import HpFeatureBlock from "../components/HpFeatureBlock";
import SEO from "../components/seo";

export default () => (
	<StaticQuery
		query={graphql`
			query {
				recraft {
					bonus: entries(section: news, limit: 6) {
						title
						... on Recraft_News {
							title
							id
							uri
							featuredImage {
								url
								focalPoint {
									x
									y
								}
							}
						}
					}
					entries(section: homepage) {
						title
						... on Recraft_Homepage {
							postDate
							superBuilder {
								__typename
								... on Recraft_SuperBuilderCbBlock {
									anchorName
									enabled

									fetchers {
										newsGrabber {
											__typename
											... on Recraft_NewsGrabberNewsGrabber {
												newsCategory {
													id
													title
												}
												displayFormat_FieldData {
													selected {
														value
													}
												}
											}
										}
									}
									innerContentBuilder {
										id
										... on Recraft_InnerContentBuilderSuperTableBlockType {
											innerContentBuilder {
												... on Recraft_InnerContentBuilderCopy {
													copy {
														content
													}
												}
												... on Recraft_InnerContentBuilderHeading {
													heading
													subhead
												}
												... on Recraft_InnerContentBuilderBlockquote {
													blockquote
													sourceName
													sourceLink
												}
												... on Recraft_InnerContentBuilderImageBlock {
													image {
														id
														url
													}
													customCaption
												}
												... on Recraft_InnerContentBuilderImageCollection {
													imageCollection {
														url
													}
													displayFormat
												}
												... on Recraft_InnerContentBuilderSidebarEntries {
													heading
													floatSide
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => (
			<Layout>
				<SEO title="Welcome to Dumbo" keywords={[`dumbo`]} />
				<div>
					<HpFeatureBlock />
					{data.recraft.bonus ? (
						<div className="px-6 relative z-10 mb-12">
							<Cardboard>
								<CardSelections
									cardlistTitle={"Recent Dumbo News"}
									allLink={"blogging"}
									allName={"See more news"}
									items={data.recraft.bonus}
								/>
							</Cardboard>
						</div>
					) : null}
				</div>
			</Layout>
		)}
	/>
);
